import React from 'react';
import { FaFileCsv } from 'react-icons/fa';
import {
  Button, Form, Loader, Modal, Schema, Stack, TagPicker,
} from 'rsuite';
import CardActionImportGuide from './CardActionImportGuide';
import { downloadData } from '../utils/downloadData';
import {
  FilterParams,
} from '../api/products';
import { ALLEGRO_MARKETPLACES } from '../api/model/Marketplaces';

type DownloadReportProps = {
  reportName: string;
  getReport: ((params: FilterParams) => Promise<any>);
};

export default function DownloadReport({ reportName, getReport }: DownloadReportProps) {
  const [open, setOpen] = React.useState<boolean>(false);
  const [generating, setGenerating] = React.useState<boolean>(false);
  const [formValue, setFormValue] = React.useState<Record<string, any>>({
    categories: '',
    brands: '',
    marketplaces: [],
    minStock: '',
  });

  const handleClose = () => {
    setOpen(false);
    setGenerating(false);
  };

  const downloadFile = async () => {
    setGenerating(true);
    const response = await getReport(formValue as FilterParams);
    const fileName = reportName.toLowerCase().replace(' ', '-');
    downloadData(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', `${fileName}.xlsx`);
    handleClose();
  };

  const model = Schema.Model({
    categories: Schema.Types.StringType().pattern(/^[0-9]*[;0-9]+$/, 'Categories must be a number separated by semicolon'),
    brands: Schema.Types.StringType().pattern(/^[\w ]*[;\w ]+$/, 'Brands must be separated by semicolon'),
  });

  return (
    <div>
      <CardActionImportGuide onClick={() => setOpen(true)}>
        <FaFileCsv />
        <span>{ reportName }</span>
      </CardActionImportGuide>
      <Modal
        open={open}
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Generate report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generating ? (
            <div style={{ textAlign: 'center' }}>
              <Loader size="md" />
            </div>
          ) : (
            <Form fluid model={model} formValue={formValue} onChange={setFormValue}>
              <Form.Group>
                <Form.ControlLabel>
                  Categories
                </Form.ControlLabel>
                <Form.Control
                  name="categories"
                  placeholder="Enter categories separated by semicolon"
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>
                  Brands
                </Form.ControlLabel>
                <Form.Control
                  name="brands"
                  placeholder="Enter brands separated by semicolon"
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>
                  Marketplace
                </Form.ControlLabel>
                <Form.Control
                  name="marketplaces"
                  placeholder="Select marketplace"
                  accepter={TagPicker}
                  data={ALLEGRO_MARKETPLACES}
                />
              </Form.Group>
              <Form.Group>
                <Form.ControlLabel>
                  Min stock
                </Form.ControlLabel>
                <Form.Control
                  name="minStock"
                  type="number"
                  placeholder="Min stock"
                />
              </Form.Group>
              <Stack spacing={5}>
                <Button type="submit" disabled={generating} onClick={() => downloadFile()} appearance="primary">
                  Generate Report
                </Button>
                <Button disabled={generating} onClick={() => handleClose()} appearance="subtle">
                  Close
                </Button>
              </Stack>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
