import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, FormInstance, InputPicker, Schema,
} from 'rsuite';
import { ItemDataType } from 'rsuite/CascadeTree';
import { Integration } from '../../api/apiTypes';
import { usePartnerSetupContext } from './PartnerSetupProvider';
import MpiIntegrationSetup from '../../mpi/MpiIntegrationSetup';
import BaselinkerIntegrationSetup from '../../baselinker/BaselinkerIntegrationSetup';
import ShoptetIntegrationSetup from '../../shoptet/ShoptetIntegrationSetup';
import WebEdiIntegrationSetup from '../../webedi/WebEdiIntegrationSetup';

interface Partner {
  integration: Integration;
}

interface Edit {
  integration: Integration;
}

interface State {
  edit: Edit;
}

const model = Schema.Model({
  integration: Schema.Types.StringType().isRequired(''),
});

const integrations: ItemDataType[] = [
  { value: Integration.MPI, label: 'MPI' },
  { value: Integration.BASELINKER, label: 'BaseLinker' },
  { value: Integration.SHOPTET, label: 'Shoptet' },
  { value: Integration.WEBEDI, label: 'WebEdi' },
];

function getInitialState(partner: Partner): State {
  return { edit: { ...partner } };
}

type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = EditSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'edit/set':
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}

export default function PartnerSetupPlatform() {
  const { partner, savePartner } = usePartnerSetupContext();
  const [state, dispatch] = useReducer(reducer, getInitialState(partner));
  const formRef = useRef<FormInstance>(null);

  useEffect(() => {
    const { edit } = getInitialState(partner);

    dispatch({ type: 'edit/set', payload: edit });
  }, [partner]);

  function handleChange(edit: Edit) {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSubmit() {
    if (!formRef.current?.check()) {
      return;
    }

    savePartner({ ...partner, ...state.edit });
  }

  function handleCancel() {
    const { edit } = getInitialState(partner);

    dispatch({ type: 'edit/set', payload: edit });
  }

  return (
    <>
      <Form
        ref={formRef}
        model={model}
        layout="horizontal"
        formValue={state.edit}
        onChange={(edit) => handleChange(edit as Edit)}
      >
        <Form.Group controlId="integration">
          <Form.ControlLabel>Integration</Form.ControlLabel>
          <Form.Control
            name="integration"
            accepter={InputPicker}
            data={integrations}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
            <Button
              appearance="ghost"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
      {state.edit.integration === Integration.MPI && <MpiIntegrationSetup />}
      {state.edit.integration === Integration.BASELINKER && <BaselinkerIntegrationSetup />}
      {state.edit.integration === Integration.SHOPTET && <ShoptetIntegrationSetup />}
      {state.edit.integration === Integration.WEBEDI && <WebEdiIntegrationSetup />}
    </>
  );
}
