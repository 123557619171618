// eslint-disable-next-line import/prefer-default-export
export const ALLEGRO_MARKETPLACES = [{
  value: 'ALLEGRO.SK',
  label: 'allegro.sk',
}, {
  value: 'ALLEGRO.PL',
  label: 'allegro.pl',
}, {
  value: 'ALLEGRO.CZ',
  label: 'allegro.cz',
}, {
  value: 'ALLEGRO.HU',
  label: 'allegro.hu',
}];
