import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, FormInstance, Schema,
} from 'rsuite';
import { usePartnerSetupContext } from './PartnerSetupProvider';

interface Partner {
  partnerCode: string;
  partnerName: string;
}

interface Edit {
  partnerName: string;
}

interface State {
  edit: Edit;
}

const model = Schema.Model({
  partnerName: Schema.Types.StringType().isRequired('Partner name is required.'),
});

function getInitialState(partner: Partner): State {
  return {
    edit: { ...partner },
  };
}

type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = EditSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'edit/set':
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}

export default function PartnerSetupMpi() {
  const { partner, savePartner } = usePartnerSetupContext();
  const [state, dispatch] = useReducer(reducer, getInitialState(partner));
  const formRef = useRef<FormInstance>(null);

  useEffect(() => {
    const { edit } = getInitialState(partner);

    dispatch({ type: 'edit/set', payload: edit });
  }, [partner]);

  function handleChange(edit: Edit) {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSubmit() {
    if (!formRef.current?.check()) {
      return;
    }

    savePartner({ ...partner, ...state.edit });
  }

  function handleCancel() {
    const { edit } = getInitialState(partner);

    dispatch({ type: 'edit/set', payload: edit });
  }

  return (
    <Form
      ref={formRef}
      model={model}
      layout="horizontal"
      formValue={state.edit}
      onChange={(edit) => handleChange(edit as Edit)}
    >
      <Form.Group controlId="partnerCode">
        <Form.ControlLabel>Partner code</Form.ControlLabel>
        <Form.Control name="partnerCode" readOnly />
      </Form.Group>
      <Form.Group controlId="partnerName">
        <Form.ControlLabel>Partner name</Form.ControlLabel>
        <Form.Control name="partnerName" />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            onClick={() => handleSubmit()}
          >
            Submit
          </Button>
          <Button
            appearance="ghost"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
}
