import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Divider, Nav } from 'rsuite';
import { PARTNER_SETUP_PLATFORM } from '../routes/links';
import { Eshop, EshopSetup, getEshopSetup } from '../api/eshop/eshop';
import PlatformIdSync from './PlatformIdSync';
import CustomFieldsForm from './CustomFieldsForm';
import OrderStatusesForm from './OrderStatusesForm';
import BasicInformationForm from './BasicInformationForm';
import { EshopContext } from './EshopContextProvider';

interface BaselinkerIntegrationEshopFormProps {
  blApiToken: string | undefined
}

export default function BaselinkerIntegrationEshopForm(
  { blApiToken }: BaselinkerIntegrationEshopFormProps,
) {
  const eshops = useContext<Eshop[] | undefined>(EshopContext);
  const [eshopAlreadyConfigured, setEshopAlreadyConfigured] = useState<boolean>(false);
  let { apiGroup, eshopCode } = useParams();
  apiGroup = apiGroup || 'ALZA';
  const apiGroupEshops: string[] = eshops
    ? eshops?.filter(eshop => eshop.apiGroup === apiGroup).map(eshop => eshop.code)
    : [];
  eshopCode = eshopCode || apiGroupEshops[0];

  useEffect(() => {
    getEshopSetup(eshopCode as string).then((responseData: EshopSetup) => {
      if (responseData) {
        setEshopAlreadyConfigured(true);
      } else {
        setEshopAlreadyConfigured(false);
      }
    });
  }, [eshopCode]);

  return (
    <div>
      <Nav className="nav-large" appearance="subtle" activeKey={eshopCode}>
        {apiGroupEshops.map(code => (
          <Nav.Item
            key={code}
            eventKey={code}
            as={Link}
            to={`${PARTNER_SETUP_PLATFORM}/${apiGroup}/${code}`}
          >
            {code}
          </Nav.Item>
        ))}
      </Nav>
      <br />
      <PlatformIdSync show={eshopAlreadyConfigured} eshopCodes={[eshopCode]} />
      <Divider>Custom Fields</Divider>
      <CustomFieldsForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
      <Divider>Basic information</Divider>
      <BasicInformationForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
      <Divider>Order statuses</Divider>
      <OrderStatusesForm blLinked blApiToken={blApiToken} eshopCode={eshopCode} />
    </div>
  );
}
