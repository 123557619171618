import React from 'react';
import { useParams } from 'react-router-dom';
import { ApiGroup } from '../api/model/ApiGroup';
import CarrierSetupKauflandEdit from '../components/CarrierSetup/Kaufland/CarrierSetupKauflandEdit';
import CarrierSetupAlzaEdit from '../components/CarrierSetup/Alza/CarrierSetupAlzaEdit';
import CarrierSetupAllegroEdit from '../components/CarrierSetup/Allegro/CarrierSetupAllegroEdit';
import { CarrierSetupEditProvider } from '../components/CarrierSetup/CarrierSetupEditContext';

export default function CarrierSetupEdit() {
  const { apiGroup, eshopCarrierId } = useParams();

  return (
    <div className="m-10" style={{ marginBottom: 150 }}>
      <CarrierSetupEditProvider
        apiGroup={apiGroup as ApiGroup}
        eshopCarrierId={eshopCarrierId}
      >
        {apiGroup === ApiGroup.KAUFLAND && (
          <CarrierSetupKauflandEdit />
        )}
        {apiGroup === ApiGroup.ALZA && (
          <CarrierSetupAlzaEdit />
        )}
        {apiGroup === ApiGroup.MALL_GROUP && <p>TODO: MALL_GROUP</p>}
        {apiGroup === ApiGroup.ALLEGRO && (
          <CarrierSetupAllegroEdit />
        )}
      </CarrierSetupEditProvider>
    </div>
  );
}

CarrierSetupEdit.defaultProps = {
  eshopCarrierId: undefined,
};
