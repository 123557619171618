import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, FormInstance, InputPicker, Loader, Schema, Stack, Toggle,
} from 'rsuite';
import { defaultTo, isEmpty } from 'lodash';
import axios from 'axios';
import { ItemDataType } from 'rsuite/CascadeTree';
import { PartnerEshopCrossDock } from '../../api/apiTypes';
import { Eshop, getPartnerEshopCrossDock, savePartnerEshopCrossDock } from '../../api/eshop/eshop';
import { CrossDock } from '../../api/crossdock/crossDockTrackingTypes';
import { getCrossDocks } from '../../api/crossdock/crossDock';
import showSuccessMessage from '../../utils/message';

const model = Schema.Model({
  code: Schema.Types.StringType().addRule((value, data) => {
    if (!data.enabled) {
      return true;
    }

    return !isEmpty(value);
  }, 'Select cross dock.', true),
});

interface Props {
  eshop: Eshop;
}

interface Edit {
  enabled: boolean;
  code: string;
  generateLabels: boolean;
}

type Status = 'ready' | 'loading' | 'saving';

interface State {
  status: Status
  crossDocks: CrossDock[];
  crossDock?: PartnerEshopCrossDock
  edit: Edit
}

const initialState: State = {
  status: 'ready',
  crossDocks: [],
  edit: {
    enabled: false,
    code: '',
    generateLabels: true,
  },
};

type StatusSetAction = { type: 'status/set', payload: Status };
type CrossDocksSetAction = { type: 'crossDocks/set', payload: CrossDock[] };
type CrossDockSetAction = { type: 'crossDock/set', payload: PartnerEshopCrossDock };
type EditSetAction = { type: 'edit/set', payload: Partial<Edit> };
type EditResetAction = { type: 'edit/reset' };
type Action = StatusSetAction
| CrossDocksSetAction
| CrossDockSetAction
| EditSetAction
| EditResetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set': {
      return { ...state, status: action.payload };
    }
    case 'crossDocks/set': {
      return {
        ...state,
        crossDocks: action.payload,
      };
    }
    case 'crossDock/set':
      return {
        ...state,
        crossDock: action.payload,
        edit: {
          ...state.edit,
          ...action.payload,
        },
      };
    case 'edit/set':
      return { ...state, edit: { ...state.edit, ...action.payload } };
    case 'edit/reset':
      return {
        ...state,
        edit: {
          enabled: defaultTo(
            state.crossDock?.enabled,
            initialState.edit.enabled,
          ),
          code: defaultTo(
            state.crossDock?.code,
            initialState.edit.code,
          ),
          generateLabels: defaultTo(
            state.crossDock?.generateLabels,
            initialState.edit.generateLabels,
          ),
        },
      };
    default:
      return state;
  }
}

function mapCrossDocks(crossDocks: CrossDock[]): ItemDataType[] {
  return crossDocks.map((crossDock) => ({
    value: crossDock.code,
    label: `${crossDock.code} - (${crossDock.address.country}, ${crossDock.address.city})`,
  }));
}

export default function EshopSetupCrossDock({ eshop }: Props): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const formRef = useRef<FormInstance>(null);

  async function fetchData(signal: AbortSignal): Promise<void> {
    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const crossDocks = await getCrossDocks(signal);
      const crossDock = await getPartnerEshopCrossDock(eshop.code, signal);
      dispatch({ type: 'crossDocks/set', payload: crossDocks });
      dispatch({ type: 'crossDock/set', payload: crossDock });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status !== 404) {
        throw error;
      }
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveCrossDock(): Promise<void> {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      await savePartnerEshopCrossDock(
        eshop.code,
        state.edit,
        abortControllerRef.current.signal,
      );
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
      showSuccessMessage('Cross dock saved.');
    }
  }

  function handleChange(edit: Record<string, any>): void {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSave() {
    if (!formRef.current?.check()) {
      return;
    }

    saveCrossDock().then();
  }

  function handleCancel(): void {
    dispatch({ type: 'edit/reset' });
  }

  useEffect(
    () => () => { abortControllerRef.current.abort(); },
    [abortControllerRef.current],
  );

  useEffect(() => {
    const abortController = new AbortController();

    fetchData(abortController.signal).then();
    formRef.current?.cleanErrors();

    return () => {
      abortController.abort();
    };
  }, [eshop]);

  useEffect(() => {
    formRef.current?.check();
  }, [state]);

  if (state.status === 'loading') {
    return <Loader />;
  }

  const crossDocks = mapCrossDocks(state.crossDocks);

  return (
    <Stack>
      <Form
        layout="horizontal"
        ref={formRef}
        formValue={state.edit}
        model={model}
        onChange={(edit) => handleChange(edit)}
      >
        <Form.Group>
          <Form.ControlLabel>
            Use cross dock as delivery address
          </Form.ControlLabel>
          <Form.Control
            name="enabled"
            checked={state.edit.enabled}
            accepter={Toggle}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Select cross dock
          </Form.ControlLabel>
          <Form.Control
            name="code"
            accepter={InputPicker}
            data={crossDocks}
          />
        </Form.Group>
        <Form.Group>
          <Form.ControlLabel>
            Generate shipping labels automatically
          </Form.ControlLabel>
          <Form.Control
            name="generateLabels"
            checked={state.edit.generateLabels}
            accepter={Toggle}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSave()}
              type="submit"
              loading={state.status === 'saving'}
            >
              Save
            </Button>
            <Button
              appearance="ghost"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </Stack>
  );
}
