import React from 'react';
import PartnerSetupDetail from '../components/PartnerSetup/PartnerSetupDetail';
import { PartnerSetupProvider } from '../components/PartnerSetup/PartnerSetupProvider';

export default function PartnerSetup() {
  return (
    <PartnerSetupProvider>
      <PartnerSetupDetail />
    </PartnerSetupProvider>
  );
}
