import React, { useEffect, useReducer, useRef } from 'react';
import { Stack } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Carrier, EshopCarrier, MpiDeliveryType } from '../../../api/apiTypes';
import { getAllegroDeliveryMethods, getAllegroDeliveryServices, getAllegroOrderCarriers } from '../../../api/allegro';
import { getCarriers, getEshopCarriers } from '../../../api/carrier';
import { AllegroDeliveryMethod, AllegroDeliveryService, AllegroOrderCarrier } from '../../../api/allegroApiTypes';
import { utils } from '../../../utils/utils';
import CarrierSetupAllegroEditForm from './CarrierSetupAllegroEditForm';
import showSuccessMessage from '../../../utils/message';
import { useCarrierSetupEditContext } from '../CarrierSetupEditContext';

interface State {
  eshopCarrier?: EshopCarrier;
  data: Data;
  edit: Edit;
  status: 'loading' | 'ready';
}

interface Data {
  deliveryMethods: AllegroDeliveryMethod[];
  orderCarriers: AllegroOrderCarrier[];
  deliveryServices: AllegroDeliveryService[];
  eshopCarriers: EshopCarrier[];
  carriers: Carrier[];
}

interface Edit {
  eshopCode?: string;
  eshopCarrierCode?: string;
  secondaryEshopCarrierCode?: string;
  managedByPartner?: string;
  eshopCarrierType?: string;
  deliveryType?: string;
  carrierId?: string;
  portalName?: string;
}

const initialData: Data = {
  deliveryMethods: [],
  orderCarriers: [],
  deliveryServices: [],
  eshopCarriers: [],
  carriers: [],
};

const initialState: State = {
  eshopCarrier: undefined,
  data: initialData,
  edit: {
    eshopCode: '',
    eshopCarrierCode: '',
    secondaryEshopCarrierCode: '',
    managedByPartner: 'false',
    eshopCarrierType: '',
    deliveryType: '',
    carrierId: '',
    portalName: '',
  },
  status: 'ready',
};

type SetEshopCarrierAction = { type: 'setEshopCarrier', payload: EshopCarrier };
type LoadAction = { type: 'load' };
type SetDataAction = { type: 'setData', payload: Data };
type SetEditAction = { type: 'setEdit', payload: Edit };
type SaveAction = { type: 'save', payload: boolean };
type Action = SetEshopCarrierAction
| LoadAction
| SetDataAction
| SetEditAction
| SaveAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setEshopCarrier':
      return {
        ...state,
        eshopCarrier: action.payload,
        edit: {
          eshopCode: utils.defaultToEmpty(action.payload.eshopCode),
          eshopCarrierCode: utils.defaultToEmpty(action.payload.eshopCarrierCode),
          secondaryEshopCarrierCode: utils.defaultToEmpty(action.payload.secondaryEshopCarrierCode),
          managedByPartner: action.payload.managedByPartner?.toString() ?? 'false',
          eshopCarrierType: utils.defaultToEmpty(action.payload.eshopCarrierType),
          deliveryType: utils.defaultToEmpty(action.payload.defaultMpiDeliveryType),
          carrierId: utils.defaultToEmpty(action.payload.carrierId?.toString()),
          portalName: utils.defaultToEmpty(action.payload.portalName),
        },
      };
    case 'load':
      return { ...state, status: 'loading', data: { ...initialData } };
    case 'setData':
      return {
        ...state,
        status: 'ready',
        data: action.payload,
      };
    case 'setEdit':
      return {
        ...state,
        edit: {
          ...action.payload,
          eshopCarrierCode: state.edit.eshopCode === action.payload.eshopCode ? action.payload.eshopCarrierCode : '',
          eshopCarrierType: action.payload.managedByPartner === 'true'
            ? action.payload.eshopCarrierType
            : '',
        },
      };
    default:
      return state;
  }
}

export default function CarrierSetupAllegroEdit(): JSX.Element {
  const {
    apiGroup,
    eshopCarrier,
    eshops,
    isSaving,
    saveEshopCarrier,
  } = useCarrierSetupEditContext();
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const abortControllerRef = useRef<AbortController>(new AbortController());

  const filteredEshopCodes = eshops
    .filter((eshop) => eshop.apiGroup === apiGroup);
  const filteredDeliveryMethods = state.data.deliveryMethods
    .filter((deliveryMethod) => {
      if (deliveryMethod.id === eshopCarrier?.eshopCarrierCode) {
        return true;
      }

      const { eshopCarriers } = state.data;
      const { eshopCode } = state.edit;
      const marketplace = eshopCode?.toLowerCase().replace('.', '-') ?? '';

      return deliveryMethod.marketplaces.includes(marketplace)
        && !eshopCarriers.some((value) => value.eshopCarrierCode === deliveryMethod.id);
    });
  const filledOrderCarriers = state.data.orderCarriers
    .map((orderCarrier) => ({ id: orderCarrier.id, name: orderCarrier.name ?? orderCarrier.id }));
  const mappedCarriers = state.data.carriers
    .map(carrier => ({ ...carrier, id: carrier.id.toString() }));

  function fetchData(eshopCode: string, signal: AbortSignal): void {
    dispatch({ type: 'load' });
    Promise
      .all([
        getAllegroDeliveryMethods(eshopCode, signal)
          .catch((error) => utils.ignoreUnauthorized(error, [])),
        getAllegroOrderCarriers(eshopCode, signal)
          .catch((error) => utils.ignoreUnauthorized(error, [])),
        getAllegroDeliveryServices(eshopCode, signal)
          .catch((error) => utils.ignoreUnauthorized(error, [])),
        getEshopCarriers({ eshopCode }, signal),
        getCarriers(signal),
      ])
      .then((response) => {
        const [
          deliveryMethods,
          orderCarriers,
          deliveryServices,
          eshopCarriers,
          carriers,
        ] = response;
        dispatch({
          type: 'setData',
          payload: {
            deliveryMethods, orderCarriers, deliveryServices, eshopCarriers, carriers,
          },
        });
      });
  }

  async function save(): Promise<void> {
    if (abortControllerRef.current.signal.aborted) {
      abortControllerRef.current = new AbortController();
    }

    const { edit } = state;
    const editedEshopCarrier = {
      ...eshopCarrier,
      eshopCode: edit.eshopCode,
      eshopCarrierCode: edit.eshopCarrierCode,
      secondaryEshopCarrierCode: edit.secondaryEshopCarrierCode || undefined,
      name: _.find(
        filteredDeliveryMethods,
        (deliveryMethod) => deliveryMethod.id === edit.eshopCarrierCode,
      )?.name,
      managedByPartner: edit.managedByPartner === 'true',
      eshopCarrierType: edit.eshopCarrierType,
      defaultMpiDeliveryType: edit.deliveryType
        ? MpiDeliveryType[edit.deliveryType as keyof typeof MpiDeliveryType]
        : undefined,
      carrierId: Number(edit.carrierId),
      portalName: edit.portalName,
    };

    await saveEshopCarrier(editedEshopCarrier, abortControllerRef.current.signal);
    showSuccessMessage('Carrier mapping successfully saved.');
  }

  function handleEdit(edit: Edit) {
    dispatch({ type: 'setEdit', payload: edit });
  }

  function handleSave(): void {
    save().then();
  }

  function handleCancel(): void {
    navigate(-1);
  }

  useEffect(() => () => {
    abortControllerRef.current.abort();
  }, [abortControllerRef.current]);

  useEffect(() => {
    if (!eshopCarrier) {
      return;
    }

    dispatch({ type: 'setEshopCarrier', payload: eshopCarrier });
  }, [eshopCarrier]);

  useEffect(() => {
    if (!state.edit.eshopCode) {
      return () => {};
    }

    const abortController = new AbortController();
    fetchData(state.edit.eshopCode, abortController.signal);

    return () => {
      abortController.abort();
    };
  }, [state.edit.eshopCode]);

  return (
    <Stack direction="column" alignItems="stretch" spacing={15} justifyContent="flex-start">
      <h4>
        {apiGroup}
        {' '}
        { eshopCarrier ? '(edit carrier mapping)' : '(new carrier mapping)'}
      </h4>
      <CarrierSetupAllegroEditForm
        edit={state.edit}
        eshopCodes={filteredEshopCodes}
        deliveryMethods={filteredDeliveryMethods}
        orderCarriers={filledOrderCarriers}
        deliveryServices={state.data.deliveryServices}
        carriers={mappedCarriers}
        isSaving={isSaving}
        isLoading={state.status === 'loading'}
        onChange={(value) => handleEdit(value)}
        onSave={() => handleSave()}
        onCancel={() => handleCancel()}
      />
    </Stack>
  );
}

CarrierSetupAllegroEdit.defaultProps = {
  eshopCarrier: undefined,
};
