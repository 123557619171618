import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from '@rsuite/icons';
import { MdLockOutline } from 'react-icons/md';
import {
  Avatar, Button, ButtonToolbar, FlexboxGrid, Form, Schema, Stack,
} from 'rsuite';
import Copyright from '../components/Copyright';

import { ORDERS } from '../routes/links';

import { useStoreActions, useStoreState } from '../store/hooks';

export default function SignIn() {
  const navigate = useNavigate();
  const user = useStoreState(state => state.user.currentUser);
  const { login } = useStoreActions(actions => actions.user);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({ username: '', password: '' });

  const handleChange = (formValue: any) => {
    setFormData(formValue);
  };

  const handleSubmit = async (data: Record<string, any> | null) => {
    if (data) {
      setLoading(true);
      try {
        await login(formData);
        navigate(ORDERS);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (user.username) {
      navigate(ORDERS);
    }
  }, [user]);

  const model = Schema.Model({
    username: Schema.Types.StringType().isRequired(),
    password: Schema.Types.StringType().isRequired(),
  });

  return (
    <FlexboxGrid align="top" justify="center">
      <FlexboxGrid.Item>
        <Stack direction="column" spacing={2} className="mt-20">
          <Avatar circle style={{ backgroundColor: '#9c27b0' }}>
            <Icon as={MdLockOutline} />
          </Avatar>

          <h3>
            Sign in
          </h3>
          <Form model={model} onSubmit={handleSubmit} onChange={handleChange}>
            <Form.Group controlId="username">
              <Form.ControlLabel>Username</Form.ControlLabel>
              <Form.Control name="username" />
            </Form.Group>

            <Form.Group controlId="password">
              <Form.ControlLabel>Password</Form.ControlLabel>
              <Form.Control name="password" type="password" autoComplete="off" />
            </Form.Group>
            <Form.Group>
              <ButtonToolbar>
                <Button type="submit" appearance="primary" block disabled={loading}>Sign In</Button>
              </ButtonToolbar>
            </Form.Group>

          </Form>

        </Stack>

        <div className="mt-20">
          <Copyright />
        </div>

      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
}
