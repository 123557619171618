import React, { useEffect, useReducer } from 'react';
import {
  ButtonToolbar, CheckPicker, IconButton, SelectPicker, Stack, Table, Toggle,
} from 'rsuite';
import _ from 'lodash';
import { ItemDataType } from 'rsuite/CascadeTree';
import { BsPlusCircle } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { Transformation } from '../../api/feeds/xmlMorpherApiTypes';
import { Feed, FeedType, getFeedTypeLabel } from '../../api/feeds/feedApiTypes';
import { utils } from '../../utils/utils';
import { getFeeds, putFeed } from '../../api/feeds/feed';
import { getTransformations } from '../../api/feeds/xmlMorpher';
import { Eshop, getEshops } from '../../api/eshop/eshop';
import { PARTNER_SETUP_FEED_ADD } from '../../routes/links';

interface Props {
  filter?: string
}

interface State {
  status: 'ready' | 'loading';
  feeds: JoinedFeed[];
  filteredFeeds: JoinedFeed[];
  transformationNames: ItemDataType[];
  feedTypes: ItemDataType[];
  countryCodes: ItemDataType[];
  marketplaceCodes: ItemDataType[];
  filter?: string;
}

interface JoinedFeed extends Feed {
  transformation?: Transformation;
}

type StatusSetAction = { type: 'status/set', payload: 'ready' | 'loading' };
type DataFetchedAction = { type: 'data/fetched', payload: [Feed[], Transformation[], Eshop[]] };
type FeedsSetAction = { type: 'feeds/set', payload: JoinedFeed[]; };
type FilterSetAction = { type: 'filter/set', payload: string | undefined };
type Action = StatusSetAction
| DataFetchedAction
| FeedsSetAction
| FilterSetAction;

const initialFeedTypes: ItemDataType[] = Object.values(FeedType)
  .map(feedType => ({ label: getFeedTypeLabel(feedType), value: feedType }));

const initialCountryCodes: ItemDataType[] = ['CZE', 'SVK', 'HUN', 'HRV', 'POL', 'SVN', 'DEU', 'AUT']
  .map(countryCode => ({ label: countryCode, value: countryCode }));

function getInitialState(filter?: string): State {
  return {
    status: 'ready',
    feeds: [],
    filteredFeeds: [],
    transformationNames: [],
    feedTypes: initialFeedTypes,
    countryCodes: initialCountryCodes,
    marketplaceCodes: [],
    filter,
  };
}

function joinFeeds(feeds: Feed[], transformations: Transformation[]): JoinedFeed[] {
  const transformationsMap = _.keyBy(transformations, 'id');

  return feeds.map(feed => {
    if (!feed.xmlMorpherId) {
      return { ...feed };
    }

    return {
      ...feed,
      transformation: transformationsMap[feed.xmlMorpherId],
    };
  });
}

function filterJoinedFeeds(joinedFeeds: JoinedFeed[], filter?: string): JoinedFeed[] {
  return utils.fulltextFiltered(
    joinedFeeds,
    filter,
    [
      'type',
      'url',
      'countryCodes',
      'marketplaceCodes',
      'transformation.name',
    ],
  );
}

function mapMarketplaceCodes(eshops: Eshop[]): ItemDataType[] {
  return eshops.map(eshop => ({ label: eshop.code, value: eshop.code }));
}

function mapTransformationNames(transformations: Transformation[]): ItemDataType[] {
  return transformations.map(transformation => ({
    label: transformation.name,
    value: transformation.id,
  }));
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'data/fetched': {
      const [feeds, transformations, eshops] = action.payload;
      const joinedFeeds = joinFeeds(feeds, transformations);
      const transformationNames = mapTransformationNames(transformations);
      const marketplaceCodes = mapMarketplaceCodes(eshops);
      const { filter } = state;

      return {
        ...state,
        feeds: joinedFeeds,
        filteredFeeds: filterJoinedFeeds(feeds, filter),
        transformationNames,
        marketplaceCodes,
      };
    }
    case 'feeds/set': {
      const feeds = action.payload;
      const { filter } = state;

      return {
        ...state,
        feeds,
        filteredFeeds: filterJoinedFeeds(feeds, filter),
      };
    }
    case 'filter/set':
    {
      const filter = action.payload;
      const { feeds } = state;

      return {
        ...state,
        filter,
        filteredFeeds: filterJoinedFeeds(feeds, filter),
      };
    }
    default:
      return state;
  }
}

export default function PartnerSetupFeedTable({ filter }: Props) {
  const [state, dispatch] = useReducer(reducer, getInitialState(filter));
  const navigate = useNavigate();

  async function fetchData() {
    dispatch({ type: 'status/set', payload: 'loading' });
    try {
      const data = await Promise.all([
        getFeeds(),
        getTransformations(),
        getEshops().then(response => response.data),
      ]);

      dispatch({ type: 'data/fetched', payload: data });
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function handleChange<Key extends keyof Feed>(
    feed: Feed,
    key: Key,
    value: Feed[Key],
  ) {
    const updatedFeed = { ...feed, [key]: value };
    const updatedFeeds = state.feeds.map((item) => {
      if (item.id !== feed.id) {
        return item;
      }

      return updatedFeed;
    });

    dispatch({ type: 'feeds/set', payload: updatedFeeds });

    await putFeed(updatedFeed);
  }

  function handleAdd() {
    navigate(PARTNER_SETUP_FEED_ADD);
  }

  useEffect(() => {
    fetchData().then();
  }, []);

  useEffect(() => {
    dispatch({ type: 'filter/set', payload: filter });
  }, [filter]);

  return (
    <Stack
      alignItems="stretch"
      justifyContent="flex-start"
      direction="column"
      spacing="20px"
    >
      <Table
        autoHeight
        data={state.filteredFeeds}
        loading={state.status === 'loading'}
        rowHeight={60}
      >
        <Table.Column flexGrow={1} verticalAlign="middle" align="center">
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.Cell dataKey="id" align="center" />
        </Table.Column>
        <Table.Column flexGrow={3} verticalAlign="middle" align="center">
          <Table.HeaderCell>Transformation name</Table.HeaderCell>
          <Table.Cell align="center" verticalAlign="middle">
            {(joinedFeed: JoinedFeed) => (
              <SelectPicker
                data={state.transformationNames}
                value={joinedFeed.xmlMorpherId}
                onChange={(xmlMorpherId) => handleChange(
                  joinedFeed,
                  'xmlMorpherId',
                  xmlMorpherId as number,
                )}
                searchable={false}
                cleanable={false}
                style={{ width: '100%' }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} verticalAlign="middle" align="center">
          <Table.HeaderCell>Feed type</Table.HeaderCell>
          <Table.Cell align="center" verticalAlign="middle">
            {(joinedFeed: JoinedFeed) => (
              <SelectPicker
                data={state.feedTypes}
                value={joinedFeed.type}
                onChange={(feedType) => handleChange(
                  joinedFeed,
                  'type',
                  feedType as FeedType,
                )}
                searchable={false}
                cleanable={false}
                style={{ width: '100%' }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} verticalAlign="middle" align="center">
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.Cell align="center" verticalAlign="middle">
            {(joinedFeed: JoinedFeed) => (
              <CheckPicker
                data={state.countryCodes}
                value={joinedFeed.countryCodes}
                onChange={(countryCodes) => handleChange(
                  joinedFeed,
                  'countryCodes',
                  countryCodes as string[],
                )}
                searchable={false}
                style={{ width: '100%' }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} verticalAlign="middle" align="center">
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell align="center" verticalAlign="middle">
            {(joinedFeed: JoinedFeed) => (
              <CheckPicker
                data={state.marketplaceCodes}
                value={joinedFeed.marketplaceCodes}
                onChange={(marketplaceCodes) => handleChange(
                  joinedFeed,
                  'marketplaceCodes',
                  marketplaceCodes as string[],
                )}
                searchable={false}
                style={{ width: '100%' }}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column flexGrow={2} verticalAlign="middle" align="center">
          <Table.HeaderCell>Enabled</Table.HeaderCell>
          <Table.Cell align="center" verticalAlign="middle">
            {(joinedFeed: JoinedFeed) => (
              <Toggle
                checked={joinedFeed.enabled}
                onChange={(enabled) => handleChange(joinedFeed, 'enabled', enabled)}
              />
            )}
          </Table.Cell>
        </Table.Column>
      </Table>
      <ButtonToolbar>
        <IconButton
          size="md"
          appearance="subtle"
          icon={<BsPlusCircle size={20} />}
          onClick={() => handleAdd()}
        />
      </ButtonToolbar>
    </Stack>
  );
}

PartnerSetupFeedTable.defaultProps = {
  filter: undefined,
};
