import React from 'react';
import { Button, Popover, Whisper } from 'rsuite';
import { StateMapper } from 'easy-peasy';
import { OverlayTriggerType } from 'rsuite/cjs/internals/Overlay/OverlayTrigger';
import { Permission, UserStoreModel } from '../../store/user/types';
import { OrderListItem, UpdateStatus } from '../../api/apiTypes';
import { useStoreState } from '../../store/hooks';

interface StatusWhisperProps {
  order: OrderListItem,
  onRevertStatus?: (id: number) => void,
  children: JSX.Element,
}

function getTrigger(order: OrderListItem): OverlayTriggerType {
  const { updateStatus } = order;
  return updateStatus === UpdateStatus.UPDATE_PENDING ? 'hover' : 'none';
}

function getText(order: OrderListItem): string {
  const { updateStatusResponseError } = order;
  return updateStatusResponseError || 'Update pending';
}

function isRevertStatusVisible(user: StateMapper<Pick<UserStoreModel, any>>): boolean {
  return user.hasPermission(Permission.RevertOrderStatus);
}

function StatusWhisper({
  order,
  onRevertStatus,
  children,
}: StatusWhisperProps) {
  const currentUser = useStoreState(state => state.user);

  function handleRevertStatus() {
    onRevertStatus?.(order.id);
  }

  return (
    <Whisper
      trigger={getTrigger(order)}
      delay={1000}
      speaker={(
        <Popover
          title="Message"
          className="error-message-tooltip"
          arrow={false}
        >
          <p>{getText(order)}</p>
          {isRevertStatusVisible(currentUser) && (
          <Button
            appearance="primary"
            size="xs"
            onClick={() => handleRevertStatus()}
          >
            Revert Status
          </Button>
          )}
        </Popover>
      )}
    >
      {children}
    </Whisper>
  );
}

StatusWhisper.defaultProps = {
  onRevertStatus: undefined,
};

interface Props {
  order: OrderListItem,
  onRevertStatus?: (id: number) => void,
  onOpenStateDialog?: (order: OrderListItem) => void,
}

function getButtonClassName(order: OrderListItem): string {
  const { status, updateStatus } = order;
  const updatePendingClass = updateStatus === UpdateStatus.UPDATE_PENDING ? 'update-pending' : '';

  return `order-status-${status} ${updatePendingClass}`;
}

export default function OrdersTableStatus({
  order,
  onRevertStatus,
  onOpenStateDialog,
}: Props) {
  function handleOpenStateDialog() {
    onOpenStateDialog?.(order);
  }

  return (
    <StatusWhisper
      order={order}
      onRevertStatus={onRevertStatus}
    >
      <Button
        size="xs"
        onClick={() => handleOpenStateDialog()}
        className={getButtonClassName(order)}
      >
        {order.status}
      </Button>
    </StatusWhisper>
  );
}

OrdersTableStatus.defaultProps = {
  onRevertStatus: undefined,
  onOpenStateDialog: undefined,
};
