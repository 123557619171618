import { Feed, FeedType } from './feedApiTypes';

// eslint-disable-next-line import/prefer-default-export
export const mockGetFeeds: Feed[] = [{
  id: 1,
  type: FeedType.STOCK,
  xmlMorpherId: 1,
  url: 'https://xml-morpher.com/1/transform',
  countryCodes: ['CZE', 'SVK'],
  marketplaceCodes: ['ALZA.CZ'],
  enabled: true,
},
{
  id: 2,
  type: FeedType.PRICE,
  xmlMorpherId: 2,
  url: 'https://xml-morpher.com/2/transform',
  countryCodes: ['POL', 'HUN', 'CZE'],
  marketplaceCodes: ['ALLEGRO.PL'],
  enabled: false,
},
{
  id: 3,
  type: FeedType.PRICE,
  url: 'https://xml-morpher.com/3/transform',
  countryCodes: ['CZE'],
  marketplaceCodes: ['MALL.CZ'],
  enabled: true,
}];
