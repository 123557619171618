import api from '../base';
import { Transformation } from './xmlMorpherApiTypes';
import { mockGetTransformations } from './xmlMorpherMock';

const PROTECTED_BASE_PATH = 'xml-morpher/api/transformations';

// eslint-disable-next-line import/prefer-default-export
export async function getTransformations(signal?: AbortSignal): Promise<Transformation[]> {
  const { data } = await api.get<Transformation[]>(
    PROTECTED_BASE_PATH,
    { signal },
  );
  return data;
}

api.mock
  .onGet(/\/xml-morpher\/api\/transformations$/)
  .reply(200, mockGetTransformations);
