import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import UpdateStocksAndPrices from '../pages/UpdateStocksAndPrices';
import ImportProducts from '../pages/ImportProducts';
import PageNotFound from '../pages/PageNotFound';
import SignIn from '../pages/SignIn';
import PrivateRoute from './PrivateRoute';
import {
  ACCOUNT_LINKED,
  ALLEGRO_IMPORT,
  BATCH_UPDATE,
  CARRIER_SETUP,
  CARRIER_SETUP_EDIT,
  ESHOP_SETUP,
  ESHOP_SETUP_OVERVIEW,
  FEED_MORPHER,
  FEEDS,
  INDEX,
  KAUF_IMPORT,
  LABEL_CREATION,
  ORDERS,
  PARTNER_SETUP,
  PARTNER_SETUP_FEED_ADD,
  PARTNER_SETUP_OVERVIEW,
  PRODUCT_IMPORT,
  PRODUCTS,
  PROFILE,
  SHIPMENTS,
  UPDATE_STOCKS_AND_PRICES,
  USER_SETUP,
  USER_SETUP_CREATE,
  WAREHOUSE_LOCALITY_EDIT,
  YOU_ARE_NOT_AUTHORIZED,
} from './links';
import BatchUpdate from '../pages/BatchUpdate';
import Orders from '../pages/Orders';
import Shipments from '../pages/Shipments';
import Products from '../pages/Products';
import UserProfile from '../pages/UserProfile';
import AccountLinkingStatus from '../pages/AccountLinkingStatus';
import ShippingLabels from '../pages/ShippingLabels';
import Feeds from '../pages/Feeds';
import PartnerSetup from '../pages/PartnerSetup';
import PartnersTable from '../pages/PartnersTable';
import { Permission } from '../store/user/types';
import UsersTable from '../pages/UsersTable';
import UserSetup from '../pages/UserSetup';
import PermissionProtectedRoute from './PermissionProtectedRoute';
import YouAreNotAuthorized from '../pages/YouAreNotAuthorized';
import EshopSetup from '../pages/EshopSetup';
import { useStoreActions, useStoreState } from '../store/hooks';
import CarrierSetup from '../pages/CarrierSetup';
import CarrierSetupEdit from '../pages/CarrierSetupEdit';
import EshopSetupWarehouseLocalityEdit from '../pages/EshopSetupWarehouseLocalityEdit';
import ImportOffers from '../pages/ImportOffers';
import PartnerSetupFeedAdd from '../components/PartnerSetup/PartnerSetupFeedAdd';
import FeedMorpher from '../pages/FeedMorpher';

function createPermissionProtectedRoute(
  path: string,
  requiredPermission: Permission,
  element: JSX.Element,
) {
  return (
    <Route
      path={path}
      element={(
        <PermissionProtectedRoute
          requiredPermission={requiredPermission}
          element={element}
        />
      )}
    />
  );
}

export default function RoutesConfig() {
  const { currentUser } = useStoreState(state => state.user);
  const { changePartner } = useStoreActions(actions => actions.user);
  const navigate = useNavigate();

  function navigateToSetup(partnerCode: string, path: string): void {
    if (currentUser.partnerCode !== partnerCode) {
      changePartner(partnerCode);
    }
    navigate(path);
  }

  return (
    <Routes>
      <Route path={INDEX} element={<SignIn />} />
      <Route element={<PrivateRoute><Layout /></PrivateRoute>}>
        {createPermissionProtectedRoute(
          PRODUCT_IMPORT,
          Permission.ProductImport,
          <ImportProducts />,
        )}
        {createPermissionProtectedRoute(
          KAUF_IMPORT,
          Permission.KauflandImport,
          <ImportOffers key="KAUFLAND" type="KAUFLAND" />,
        )}
        {createPermissionProtectedRoute(
          ALLEGRO_IMPORT,
          Permission.AllegroImport,
          <ImportOffers key="ALLEGRO" type="ALLEGRO" />,
        )}
        {createPermissionProtectedRoute(
          LABEL_CREATION,
          Permission.LabelCreation,
          <ShippingLabels />,
        )}
        {createPermissionProtectedRoute(
          UPDATE_STOCKS_AND_PRICES,
          Permission.UpdateStocksAndPrices,
          <UpdateStocksAndPrices />,
        )}
        {createPermissionProtectedRoute(BATCH_UPDATE, Permission.BatchUpdate, <BatchUpdate />)}
        {createPermissionProtectedRoute(ORDERS, Permission.Orders, <Orders />)}
        {createPermissionProtectedRoute(SHIPMENTS, Permission.Shipments, <Shipments />)}
        {createPermissionProtectedRoute(PRODUCTS, Permission.Products, <Products />)}
        {createPermissionProtectedRoute(PROFILE, Permission.UserProfile, <UserProfile />)}
        {createPermissionProtectedRoute(
          PARTNER_SETUP_OVERVIEW,
          Permission.PartnerSetupOverview,
          <PartnersTable
            onSelection={(partnerCode) => navigateToSetup(partnerCode, PARTNER_SETUP)}
          />,
        )}
        {createPermissionProtectedRoute(PARTNER_SETUP, Permission.PartnerSetup, <PartnerSetup />)}
        {createPermissionProtectedRoute(
          `${PARTNER_SETUP}/:tab`,
          Permission.PartnerSetup,
          <PartnerSetup />,
        )}
        {createPermissionProtectedRoute(
          `${PARTNER_SETUP}/:tab/:apiGroup`,
          Permission.PartnerSetup,
          <PartnerSetup />,
        )}
        {createPermissionProtectedRoute(
          `${PARTNER_SETUP}/:tab/:apiGroup/:eshopCode`,
          Permission.PartnerSetup,
          <PartnerSetup />,
        )}
        {createPermissionProtectedRoute(
          PARTNER_SETUP_FEED_ADD,
          Permission.PartnerSetup,
          <PartnerSetupFeedAdd />,
        )}
        {createPermissionProtectedRoute(
          ESHOP_SETUP_OVERVIEW,
          Permission.EshopSetupOverview,
          <PartnersTable
            onSelection={(partnerCode) => navigateToSetup(partnerCode, ESHOP_SETUP)}
          />,
        )}
        {createPermissionProtectedRoute(
          ESHOP_SETUP,
          Permission.EshopSetup,
          <EshopSetup />,
        )}
        {createPermissionProtectedRoute(
          `${ESHOP_SETUP}/:group`,
          Permission.EshopSetup,
          <EshopSetup />,
        )}
        {createPermissionProtectedRoute(
          `${ESHOP_SETUP}/:group/:code`,
          Permission.EshopSetup,
          <EshopSetup />,
        )}
        {createPermissionProtectedRoute(
          `${ESHOP_SETUP}/:apiGroup/:eshopCode/${WAREHOUSE_LOCALITY_EDIT}/:warehouseLocalityId`,
          Permission.EshopSetup,
          <EshopSetupWarehouseLocalityEdit />,
        )}
        {createPermissionProtectedRoute(
          `${ESHOP_SETUP}/:apiGroup/:eshopCode/${WAREHOUSE_LOCALITY_EDIT}`,
          Permission.EshopSetup,
          <EshopSetupWarehouseLocalityEdit />,
        )}
        {createPermissionProtectedRoute(USER_SETUP, Permission.UserSetup, <UsersTable />)}
        {createPermissionProtectedRoute(
          USER_SETUP_CREATE,
          Permission.UserSetupCreate,
          <UserSetup />,
        )}
        {createPermissionProtectedRoute(
          `${CARRIER_SETUP}`,
          Permission.CarrierSetup,
          <CarrierSetup />,
        )}
        {createPermissionProtectedRoute(
          `${CARRIER_SETUP}/:apiGroup`,
          Permission.CarrierSetup,
          <CarrierSetup />,
        )}
        {createPermissionProtectedRoute(
          `${CARRIER_SETUP}/:apiGroup/${CARRIER_SETUP_EDIT}/:eshopCarrierId`,
          Permission.CarrierSetup,
          <CarrierSetupEdit />,
        )}
        {createPermissionProtectedRoute(
          `${CARRIER_SETUP}/:apiGroup/${CARRIER_SETUP_EDIT}`,
          Permission.CarrierSetup,
          <CarrierSetupEdit />,
        )}
        {createPermissionProtectedRoute(
          `${FEED_MORPHER}`,
          Permission.FeedMorpher,
          <FeedMorpher />,
        )}
      </Route>
      <Route path={FEEDS} element={<Feeds />} />
      <Route path={ACCOUNT_LINKED} element={<AccountLinkingStatus />} />
      <Route path={YOU_ARE_NOT_AUTHORIZED} element={<YouAreNotAuthorized />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}
