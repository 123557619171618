import axios, { AxiosResponseHeaders } from 'axios';
import _, { defaultTo } from 'lodash';

export module utils {
  export function defaultToUndefined<T>(value: T): T | undefined {
    return defaultTo(value, undefined);
  }

  export function defaultToEmpty(value: string | undefined | null): string {
    return defaultTo(value, '');
  }

  export function ignoreUnauthorized<T>(error: unknown, resolveValue: T): Promise<T> {
    if (axios.isAxiosError(error) && error.response?.status !== 401) {
      return Promise.reject(error);
    }

    return Promise.resolve(resolveValue);
  }

  export function paginated<T>(array: T[], paging: { page: number, limit: number }) {
    const { page, limit } = paging;
    const start = (page - 1) * limit;
    const end = start + limit;
    return array.slice(start, end);
  }

  export function fulltextFiltered<T = object>(
    array: T[],
    filter?: string,
    properties: (keyof T | string)[] = [],
  ): T[] {
    if (!filter) {
      return array;
    }

    const lowerCasedFilter = filter.toLowerCase();

    return array
      .filter((object: T) => properties.some((property) => {
        const value = _.get(object, property);
        if (!value) {
          return false;
        }

        return String(value)
          .toLowerCase()
          .includes(lowerCasedFilter);
      }));
  }

  export async function sleep(milliseconds: number): Promise<void> {
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds);
    });
  }

  export function getIdFromLocationHeader(headers: AxiosResponseHeaders): string {
    const { location } = headers;
    const split = _.split(location, '/');

    return _.last(split) as string;
  }
}
