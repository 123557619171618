export enum FeedType {
  STOCK = 'STOCK',
  PRICE = 'PRICE',
}

export function getFeedTypeLabel(feedType: FeedType): string {
  return {
    [FeedType.STOCK]: 'Availability feed',
    [FeedType.PRICE]: 'Price feed',
  }[feedType];
}

export type Feed = {
  id: number;
  type: FeedType;
  xmlMorpherId?: number;
  url: string;
  countryCodes: string[];
  marketplaceCodes: string[];
  enabled: boolean;
};
