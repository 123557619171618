import React, { useEffect, useReducer, useRef } from 'react';
import {
  Dropdown,
  IconButton,
} from 'rsuite';
import { Icon } from '@rsuite/icons';
import { HiSquare3Stack3D } from 'react-icons/hi2';
import ProductBulkActionDialog from './ProductBulkActionDialog';
import FileUpload from '../../FileUpload';
import globalHandleError from '../../../api/apiErrorHandle';
import { OffersBulkActionResponse } from '../../../api/apiTypes';

enum BulkAction {
  PUBLISH = 'PUBLISH',
  ACTIVATE = 'ACTIVATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  HOLD = 'HOLD',
  REMOVE_HOLD = 'REMOVE_HOLD',
  MPI_ONLY = 'MPI_ONLY',
}

const BulkActionLabels: { [key in BulkAction]: string } = {
  [BulkAction.PUBLISH]: 'Publish',
  [BulkAction.ACTIVATE]: 'Activate',
  [BulkAction.UPDATE]: 'Update',
  [BulkAction.DELETE]: 'Delete',
  [BulkAction.HOLD]: 'Hold',
  [BulkAction.REMOVE_HOLD]: 'Remove hold',
  [BulkAction.MPI_ONLY]: 'Mpi only',
};

interface State {
  status: 'ready' | 'loading';
  clickEvent?: {
    bulkAction: BulkAction;
    time: number;
  };
  response?: OffersBulkActionResponse;
}

const initialState: State = {
  status: 'ready',
  clickEvent: undefined,
  response: undefined,
};

type ClickEventSetAction = { type: 'clickEvent/set', payload: BulkAction };
type ResponseLoadAction = { type: 'response/load' };
type ResponseSetAction = { type: 'response/set', payload?: OffersBulkActionResponse };
type Action = ClickEventSetAction | ResponseLoadAction | ResponseSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'clickEvent/set':
      return {
        ...state,
        clickEvent: {
          bulkAction: action.payload,
          time: Date.now(),
        },
      };
    case 'response/load':
      return { ...state, response: undefined, status: 'loading' };
    case 'response/set':
      return { ...state, response: action.payload, status: 'ready' };
    default:
      return state;
  }
}

export default function ProductBulkActionDropdown(): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const fileUploadRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!state.clickEvent || !fileUploadRef.current) {
      return;
    }

    fileUploadRef.current.click();
  }, [state.clickEvent]);

  function handleClick(bulkAction: BulkAction): void {
    dispatch({ type: 'clickEvent/set', payload: bulkAction });
  }

  function handleStart(): void {
    dispatch({ type: 'response/load' });
  }

  function handleError(error: Error): void {
    globalHandleError(error);
    dispatch({ type: 'response/set', payload: undefined });
  }

  function handleSuccess(response: OffersBulkActionResponse): void {
    dispatch({ type: 'response/set', payload: response });
  }

  function handleClose() {
    dispatch({ type: 'response/set', payload: undefined });
  }

  const renderBulkActionsMenuButton = (props: any, ref: any) => (
    <IconButton
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      ref={ref}
      style={{ width: 140 }}
      appearance="primary"
      icon={<Icon as={HiSquare3Stack3D} />}
      placement="right"
    >
      Bulk actions
    </IconButton>
  );

  return (
    <>
      <Dropdown renderToggle={renderBulkActionsMenuButton}>
        {Object
          .values(BulkAction)
          .map((bulkAction) => (
            <Dropdown.Item onClick={() => handleClick(bulkAction)}>
              {BulkActionLabels[bulkAction]}
            </Dropdown.Item>
          ))}
      </Dropdown>
      <FileUpload
        path={`management/products/import/offers-bulk-action?action=${state.clickEvent?.bulkAction}`}
        onStart={() => handleStart()}
        onError={(error) => handleError(error)}
        onSuccess={(response: OffersBulkActionResponse) => handleSuccess(response)}
      >
        <button hidden ref={fileUploadRef} type="button">{}</button>
      </FileUpload>
      <ProductBulkActionDialog
        isLoading={state.status === 'loading'}
        response={state.response}
        onClose={() => handleClose()}
      />
    </>
  );
}
