import React from 'react';
import {
  Button, Input, InputGroup, Stack,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { useSearchParams } from 'react-router-dom';
import PartnerSetupFeedTable from './PartnerSetupFeedTable';

export default function PartnerSetupFeed() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter') ?? undefined;

  function handleFilter(value: string) {
    setSearchParams({ filter: value });
  }

  return (
    <Stack
      direction="column"
      alignItems="stretch"
      justifyContent="flex-start"
      spacing="20px"
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing="10px"
      >
        <InputGroup inside>
          <Input
            value={filter}
            style={{ width: '400px' }}
            onChange={(value) => handleFilter(value)}
          />
          <InputGroup.Button>
            <SearchIcon />
          </InputGroup.Button>
        </InputGroup>
        <Button
          appearance="primary"
          href="/feed-morpher"
        >
          Add new feed connection
        </Button>
      </Stack>
      <PartnerSetupFeedTable filter={filter} />
    </Stack>
  );
}
