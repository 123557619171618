import React, { useEffect, useState } from 'react';
import {
  Checkbox, IconButton, Message, Pagination, Stack, Table, useToaster,
} from 'rsuite';
import {
  FaExclamationTriangle, FaFileContract, FaMinusSquare, FaPlusSquare, FaRegFilePdf,
} from 'react-icons/fa';
import { SortType } from 'rsuite-table';
import { useStoreActions, useStoreState } from '../../store/hooks';
import { toDateFormat, toDateTimeFormat } from '../../services/dateUtils';
import { OrderListItem, OrderStates, UpdateStatus } from '../../api/apiTypes';
import CarrierCell from './CarrierCell';
import OrderPreviewRow from './OrderPreviewRow';
import LabelSubmitDrawer from '../LabelSubmit/LabelSubmitDrawer';
import formatMoneyValue from '../../utils/localization';
import OrdersStatesChangeDialog from './OrdersStatesChangeDialog';
import { revertOrderStatus } from '../../api/orders';
import OrdersTableErrorPopover from './OrdersTableErrorPopover';
import OrdersTableAction from './OrdersTableAction';
import CrossDockTrackingDrawer from './CrossDockTrackingDrawer';
import CopyToClipboardIcon from '../CopyToClipboardIcon';
import OrdersTableStatus from './OrdersTableStatus';

function TableActions({ order }:
{ order:OrderListItem }) {
  const download = () => {
    window.open(order.shippingLabelUrl);
  };

  if (order.managedByPartner) {
    return <span />;
  }

  let labelButton = null;

  if (!order.managedByPartner) {
    if (order.shippingLabelUrl) {
      labelButton = (
        <FaRegFilePdf
          title="Download shipping label"
          cursor="pointer"
          onClick={() => download()}
        />
      );
    }
  }

  return (
    <Stack spacing={5}>
      {order.packagesCount > 0 && (
      <span>
        {order.packagesCount}
        x
      </span>
      )}
      {labelButton}
      {
        !order.shippingLabelUrl
          && order.labelsError
          && <OrdersTableErrorPopover message={order.labelsError} />
      }
    </Stack>
  );
}

export function getFilterStates(view : string) : OrderStates[] {
  switch (view) {
    case 'open':
      return [OrderStates.OPEN];
    case 'shipping':
      return [OrderStates.SHIPPING];
    case 'shipped':
      return [OrderStates.SHIPPED, OrderStates.DELIVERED];
    default:
      return [];
  }
}

type Props = { view:string; selectedOrders: OrderListItem[]; selectOrders: any };
function OrdersTable({ view, selectedOrders, selectOrders }: Props) {
  const { fetchOrders, setFilter } = useStoreActions(actions => actions.orders);
  const { setOrders } = useStoreActions(actions => actions.orders);
  const {
    orders, loading, currentFilter, total,
  } = useStoreState(state => state.orders);

  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [stateDialogOpen, setStateDialogOpen] = useState(false);
  const [stateChangeOrder, setStateChangeOrder] = useState<OrderListItem | null>(null);
  const [createLabelOrder, setCreateLabelOrder] = useState<OrderListItem | null>(null);
  const [crossDockTrackingOrder, setCrossDockTracking] = useState<OrderListItem>();
  const toaster = useToaster();

  const changePage = (page:number) => {
    setFilter({
      ...currentFilter,
      page,
    });
  };

  const changeLimit = (limit: number) => {
    setFilter({
      ...currentFilter,
      pageSize: limit,
    });
  };

  useEffect(() => {
    if (JSON.stringify(getFilterStates(view)) !== JSON.stringify(currentFilter.filter.states)) {
      setFilter({
        ...currentFilter,
        page: 1,
        filter: {
          ...currentFilter.filter,
          states: getFilterStates(view),
        },
      });
    }
  }, [view]);

  useEffect(() => {
    fetchOrders({
      ...currentFilter,
      filter: {
        ...currentFilter.filter,
        // states: getFilterStates(view),
      },
    });
    setExpandedRowKeys([]);
  }, [currentFilter]);

  const handleExpanded = (rowData: any) => {
    let open = false;
    const nextExpandedRowKeys = [];

    expandedRowKeys.forEach((key) => {
      if (key === rowData.id) {
        open = true;
      } else {
        nextExpandedRowKeys.push(key);
      }
    });

    if (!open) {
      nextExpandedRowKeys.push(rowData.id);
    }

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  const handleSortColumn = (column:string, type?:SortType) => {
    setFilter({
      ...currentFilter,
      orderBy: column,
      orderDirection: type ?? 'asc',
    });
  };

  const openStateDialog = (order: OrderListItem) => {
    if (order.allowedStates && order.allowedStates.length > 0) {
      setStateDialogOpen(true);
      setStateChangeOrder(order);
    }
  };

  const closeStateDialog = () => {
    setStateDialogOpen(false);
    setStateChangeOrder(null);
  };

  const handleCheckAll = (value:any, checked:boolean) => {
    const selected = checked ? orders : [];
    selectOrders(selected);
  };
  const handleCheck = (value:any, checked:boolean) => {
    const keys = checked
      ? [...selectedOrders, value]
      : selectedOrders.filter(item => item !== value);
    selectOrders(keys);
  };

  let checked = false;
  let indeterminate = false;

  if (selectedOrders.length === orders.length) {
    checked = true;
  } else if (selectedOrders.length === 0) {
    checked = false;
  } else if (selectedOrders.length > 0 && selectedOrders.length < orders.length) {
    indeterminate = true;
  }

  useEffect(() => {
    selectOrders([]);
  }, [currentFilter.filter]);

  const revertStatus = (id: number) => {
    revertOrderStatus(id).then((response) => {
      const newOrders = orders.map(o => {
        if (o.id === response.data.orderId) {
          o.status = response.data.status;
          o.updateStatus = UpdateStatus.UP_TO_DATE;
          o.updateStatusResponseError = null;
          o.allowedStates = response.data.allowedStatusChanges;
        }

        return o;
      });
      setOrders({ total, orders: newOrders });
      toaster.push(<Message type="success" closable showIcon duration={2000}>Status reverted</Message>);
    }).catch(() => {
      toaster.push(<Message type="error" closable showIcon duration={2000}>Status has not been reverted</Message>);
    });
  };

  return (
    <div className="h100p">
      <Table
        height={400}
        fillHeight
        bordered
        data={orders}
        rowKey="id"
        loading={loading}
        onSortColumn={handleSortColumn}
        sortColumn={currentFilter.orderBy}
        sortType={currentFilter.orderDirection}
        shouldUpdateScroll={false}
        expandedRowKeys={expandedRowKeys}
        rowExpandedHeight={190}
        renderRowExpanded={rowData => <OrderPreviewRow order={rowData as OrderListItem} />}
      >
        <Table.Column width={40}>
          <Table.HeaderCell>{}</Table.HeaderCell>
          <Table.Cell>
            { rowData => rowData.updateStatusResponseError && (
            <FaExclamationTriangle
              color="red"
              cursor="pointer"
              style={{ marginTop: 5 }}
            />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={50} align="center">
          <Table.HeaderCell style={{ padding: 0 }}>
            <div style={{ lineHeight: '40px' }}>
              <Checkbox
                className="table-header-checkbox"
                inline
                checked={checked}
                indeterminate={indeterminate}
                onChange={handleCheckAll}
              />
            </div>
          </Table.HeaderCell>
          <Table.Cell
            style={{ padding: 0 }}
            dataKey="id"
          >
            {rowData => (
              <div style={{ lineHeight: '46px' }}>
                <Checkbox
                  value={rowData.id}
                  inline
                  onChange={(value, checkedd) => handleCheck(rowData as OrderListItem, checkedd)}
                  checked={selectedOrders.some(item => item.id === rowData.id)}
                />
              </div>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={50} align="center">
          <Table.HeaderCell>&nbsp;</Table.HeaderCell>
          <Table.Cell>
            {rowData => (
              <IconButton
                size="xs"
                appearance="subtle"
                icon={expandedRowKeys.some(k => k === rowData.id)
                  ? <FaMinusSquare /> : <FaPlusSquare />}
                onClick={() => handleExpanded(rowData)}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Action</Table.HeaderCell>
          <Table.Cell>
            { (rowData: OrderListItem) => (
              <OrdersTableAction
                order={rowData}
                onCreateShippingLabel={(order) => setCreateLabelOrder(order)}
                onAddCrossDockTracking={(order) => setCrossDockTracking(order)}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Marketplace</Table.HeaderCell>
          <Table.Cell dataKey="marketplace" />
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Partner order ID</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => (
                <>
                  <CopyToClipboardIcon text={rowData.partnerId} />
                  {' '}
                  {rowData.partnerId}
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={180} align="center" sortable>
          <Table.HeaderCell>Order Number (MP)</Table.HeaderCell>
          <Table.Cell>
            {
              (rowData) => (
                <>
                  <CopyToClipboardIcon text={rowData.orderNumber} />
                  {' '}
                  {rowData.orderNumber}
                </>
              )
            }
          </Table.Cell>
        </Table.Column>
        <Table.Column width={130} align="center" sortable>
          <Table.HeaderCell>Order value</Table.HeaderCell>
          <Table.Cell dataKey="orderValue">
            {rowData => (
              <span>
                {formatMoneyValue(rowData.orderValue)}
                {' '}
                {rowData.orderValueCurrency}
              </span>
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>Opened</Table.HeaderCell>
          <Table.Cell dataKey="created">
            {rowData => toDateFormat(rowData.created)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={140} align="center" sortable>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.Cell dataKey="status">
            { rowData => (
              <OrdersTableStatus
                order={rowData as OrderListItem}
                onRevertStatus={(id) => revertStatus(id)}
                onOpenStateDialog={(order) => openStateDialog(order)}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Last Status Change</Table.HeaderCell>
          <Table.Cell dataKey="statusDate">
            {rowData => toDateTimeFormat(rowData.statusDate)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} align="center" sortable>
          <Table.HeaderCell>Carrier</Table.HeaderCell>
          <Table.Cell dataKey="carrier">
            {rowData => (
              <CarrierCell
                carrier={rowData.carrier}
                apiGroup={rowData.apiGroup}
                managedByPartner={rowData.managedByPartner}
                crossDockDelivery={rowData.crossDockDelivery}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={160} align="center" sortable>
          <Table.HeaderCell>Suggested expedition</Table.HeaderCell>
          <Table.Cell dataKey="suggestedExpedition">
            {rowData => toDateTimeFormat(rowData.suggestedExpedition)}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={120} align="center" sortable>
          <Table.HeaderCell>CoD value</Table.HeaderCell>
          <Table.Cell dataKey="codValue">
            {rowData => (
              rowData.codValue > 0 && (
              <span>
                {formatMoneyValue(rowData.codValue)}
                {' '}
                {rowData.codValueCurrency}
              </span>
              )
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column width={150} align="center">
          <Table.HeaderCell>Tracking No.</Table.HeaderCell>
          <Table.Cell dataKey="trackingNumber" />
        </Table.Column>
        <Table.Column width={100} align="center">
          <Table.HeaderCell>Shipping label</Table.HeaderCell>
          <Table.Cell>
            {rowData => (
              <TableActions
                order={rowData as OrderListItem}
              />
            )}
          </Table.Cell>
        </Table.Column>
        <Table.Column>
          <Table.HeaderCell>Shipping list</Table.HeaderCell>
          <Table.Cell>
            {rowData => {
              const { shippingListUrl } = rowData as OrderListItem;
              return shippingListUrl && (
              <FaFileContract
                title="Download shipping list"
                cursor="pointer"
                onClick={() => window.open(shippingListUrl)}
              />
              );
            }}
          </Table.Cell>
        </Table.Column>
      </Table>

      <div className="mt-10">
        <Pagination
          prev
          next
          first
          last
          ellipsis
          boundaryLinks
          maxButtons={3}
          size="sm"
          layout={['total', '-', 'limit', '|', 'pager', 'skip']}
          total={total}
          limitOptions={[25, 50, 100]}
          limit={currentFilter.pageSize}
          activePage={currentFilter.page}
          onChangePage={changePage}
          onChangeLimit={changeLimit}
        />
      </div>

      <OrdersStatesChangeDialog
        open={stateDialogOpen}
        orders={stateChangeOrder ? [stateChangeOrder] : []}
        close={closeStateDialog}
      />

      <LabelSubmitDrawer orderId={createLabelOrder?.id} onClose={() => setCreateLabelOrder(null)} />
      <CrossDockTrackingDrawer
        order={crossDockTrackingOrder}
        onClose={() => setCrossDockTracking(undefined)}
      />
    </div>
  );
}

export default React.memo(OrdersTable);
