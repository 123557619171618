import { Transformation } from './xmlMorpherApiTypes';

// eslint-disable-next-line import/prefer-default-export
export const mockGetTransformations: Transformation[] = [
  {
    id: 1,
    name: 'Feed 1',
    xmlUrl: 'https://url.com/1.xml',
    xsltDefinition: 'Definition 1',
  },
  {
    id: 2,
    name: 'Feed 2',
    xmlUrl: 'https://url.com/2.xml',
    xsltDefinition: 'Definition 2',
  },
  {
    id: 3,
    name: 'Feed 3',
    xmlUrl: 'https://url.com/3.xml',
    xsltDefinition: 'Definition 3',
  },
];
