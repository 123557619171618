import React, {
  createContext, useContext, useEffect, useMemo, useReducer,
} from 'react';
import { Loader } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { EshopCarrier } from '../../api/apiTypes';
import { ApiGroup } from '../../api/model/ApiGroup';
import { Eshop, getEshops } from '../../api/eshop/eshop';
import { getEshopCarrier, postEshopCarrier } from '../../api/carrier';

interface CarrierSetupEditContext {
  apiGroup: ApiGroup;
  eshopCarrier?: EshopCarrier;
  eshops: Eshop[],
  isSaving: boolean,
  saveEshopCarrier: (
    eshopCarrier: Partial<EshopCarrier>,
    signal?: AbortSignal
  ) => Promise<void>;
}

const Context = createContext<CarrierSetupEditContext>({
  apiGroup: undefined as unknown as ApiGroup, // Hack
  eshopCarrier: undefined,
  eshops: [],
  isSaving: false,
  saveEshopCarrier: () => Promise.resolve(),
});

function useCarrierSetupEditContext() {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error('useCarrierSetupContext must be used within a Provider');
  }

  return context;
}

interface Props {
  apiGroup: ApiGroup;
  eshopCarrierId?: string;
  children: React.ReactNode;
}

type Status = 'ready' | 'loading' | 'saving';

interface State {
  status: Status;
  eshopCarrier?: EshopCarrier;
  eshops: Eshop[]
}

const initialState: State = {
  status: 'ready',
  eshopCarrier: undefined,
  eshops: [],
};

type StatusSetAction = { type: 'status/set', payload: Status };
type EshopsSetAction = { type: 'eshops/set', payload: Eshop[] };
type EshopCarrierSetAction = { type: 'eshopCarrier/set', payload: EshopCarrier };
type Action = StatusSetAction | EshopsSetAction | EshopCarrierSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set':
      return { ...state, status: action.payload };
    case 'eshops/set':
      return { ...state, eshops: action.payload };
    case 'eshopCarrier/set':
      return { ...state, eshopCarrier: action.payload };
    default:
      return state;
  }
}

function CarrierSetupEditProvider({ apiGroup, eshopCarrierId, children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  async function saveEshopCarrier(
    eshopCarrier: Partial<EshopCarrier>,
    signal?: AbortSignal,
  ) {
    dispatch({ type: 'status/set', payload: 'saving' });

    try {
      const id = await postEshopCarrier(eshopCarrier, signal);
      if (!eshopCarrierId) {
        dispatch({ type: 'eshopCarrier/set', payload: { ...state.eshopCarrier, ...eshopCarrier, id } as EshopCarrier });
        navigate(`${id}`, { replace: true });
      }
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  const context: CarrierSetupEditContext = useMemo(
    () => ({
      apiGroup,
      eshopCarrier: state.eshopCarrier,
      eshops: state.eshops,
      isSaving: state.status === 'saving',
      saveEshopCarrier,
    }),
    [state],
  );

  async function fetchData(): Promise<void> {
    dispatch({ type: 'status/set', payload: 'loading' });

    const eshops = await getEshops()
      .then((response) => response.data);
    dispatch({ type: 'eshops/set', payload: eshops });

    if (eshopCarrierId) {
      const eshopCarrier = await getEshopCarrier(+eshopCarrierId);
      dispatch({ type: 'eshopCarrier/set', payload: eshopCarrier });
    }

    dispatch({ type: 'status/set', payload: 'ready' });
  }

  useEffect(() => {
    fetchData().then();
  }, []);

  if (state.status === 'loading') {
    return <Loader />;
  }

  return (
    <Context.Provider value={context}>
      {children}
    </Context.Provider>
  );
}

CarrierSetupEditProvider.defaultProps = {
  eshopCarrierId: undefined,
};

export { CarrierSetupEditProvider, useCarrierSetupEditContext };
