import React from 'react';
import styled from '@emotion/styled';
import PartnerSetupNavigation from './PartnerSetupNavigation';
import { usePartnerSetupContext } from './PartnerSetupProvider';
import PartnerSetupMpi from './PartnerSetupMpi';
import PartnerSetupPlatform from './PartnerSetupPlatform';
import PartnerSetupFeed from './PartnerSetupFeed';

const NavigationContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`;

export default function PartnerSetupDetail() {
  const { partner, tab } = usePartnerSetupContext();

  return (
    <div className="m-10">
      <h4>{`${partner.partnerName} (${partner.partnerCode}) `}</h4>
      <NavigationContainer>
        <PartnerSetupNavigation tab={tab} />
      </NavigationContainer>
      <div className="m-10">
        {tab === 'mpi' && <PartnerSetupMpi />}
        {tab === 'platform' && <PartnerSetupPlatform />}
        {tab === 'feed' && <PartnerSetupFeed />}
      </div>
    </div>
  );
}
