import React from 'react';
import {
  Button, Col,
  FlexboxGrid, Grid, IconButton,
  Input, InputNumber, Panel, Row, Stack, Tag,
} from 'rsuite';

import {
  MdClose,
} from 'react-icons/md';

import { useStoreActions } from '../../../store/hooks';
import { LabelPackage } from '../../../store/labels/labelStoreTypes';

export default function PackageItems({
  labelPackage,
}: { labelPackage: LabelPackage }) {
  const {
    splitItem, removeItem, updateItemCount,
  } = useStoreActions(actions => actions.labels);

  const changeCount = (itemId: string, value:number) => {
    updateItemCount({ labelPackage, itemId, value });
  };

  return (
    <Panel>
      <Stack direction="row" spacing={1}>
        <h6>Items</h6>
        {labelPackage.items.length === 0
                    && <Tag>Package must have at least one item.</Tag>}
      </Stack>
      {labelPackage.isSplit && (
        <Grid>
          <Row>
            <Col xs={6}>
              <FlexboxGrid align="middle">
                <FlexboxGrid.Item colspan={8}>Item ID</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={15}>
                  <Input
                    value={labelPackage.items[0].id}
                    disabled
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={1} />
              </FlexboxGrid>
            </Col>
            <Col xs={6}>
              <FlexboxGrid align="middle">
                <FlexboxGrid.Item colspan={8}>Count</FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={15}>
                  <Input
                    value={labelPackage.items[0].count}
                    disabled
                  />
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={1} />
              </FlexboxGrid>
            </Col>
          </Row>
        </Grid>
      )}
      {!labelPackage.isSplit && (
        <Grid>
          {labelPackage.items.map((i) => (
            <Row key={`package${labelPackage.packageId}-item${i.id}`}>
              <Col xs={6}>
                <FlexboxGrid align="middle">
                  <FlexboxGrid.Item colspan={8}>Item ID</FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={15}>
                    <Input
                      value={i.id}
                      disabled
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={1} />
                </FlexboxGrid>
              </Col>
              <Col xs={6}>
                <FlexboxGrid align="middle">
                  <FlexboxGrid.Item colspan={8}>Count</FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={10}>
                    <InputNumber
                      value={i.count}
                      min={1}
                      max={i.maxCount}
                      onChange={(v) => {
                        if (v === null) return;
                        changeCount(i.id, +v);
                      }}
                    />
                  </FlexboxGrid.Item>
                  <FlexboxGrid.Item colspan={1} />
                </FlexboxGrid>
              </Col>
              <Col xs={6}>
                <Stack>
                  <Button appearance="ghost" onClick={() => splitItem({ labelPackage, itemId: i.id })}>Split item</Button>
                  <IconButton appearance="subtle" icon={<MdClose />} onClick={() => removeItem({ labelPackage, itemId: i.id })} />
                </Stack>
              </Col>
            </Row>
          ))}
        </Grid>
      )}
    </Panel>
  );
}
