import _ from 'lodash';
import api from '../base';
import { Feed } from './feedApiTypes';
import { mockGetFeeds } from './feedMock';
import { utils } from '../../utils/utils';

const BASE_PATH = 'management/feeds';

export async function getFeeds(
  signal?: AbortSignal,
): Promise<Feed[]> {
  const { data } = await api.get<Feed[]>(
    BASE_PATH,
    { signal },
  );
  return data;
}

api.mock
  .onGet('management/feeds')
  .reply(200, mockGetFeeds);

export async function postFeed(
  feed: Omit<Feed, 'id' | 'url'>,
  signal?: AbortSignal,
): Promise<number> {
  const { headers } = await api.post<void>(
    BASE_PATH,
    feed,
    { signal },
  );

  return +utils.getIdFromLocationHeader(headers);
}

api.mock
  .onPost('management/feeds')
  .reply(201, null, {
    Location: `/v1/management/feeds/${_.random(mockGetFeeds.length, mockGetFeeds.length + 10)}`,
  });

export async function putFeed(
  feed: Feed,
  signal?: AbortSignal,
): Promise<void> {
  await api.put<void>(
    `${BASE_PATH}/${feed.id}`,
    feed,
    { signal },
  );
}

api.mock
  .onPut(/management\/feeds\/\d+/)
  .reply(200);
