import {
  BackendExceptionResponse,
  PagedRequest,
  PagedResponse,
  ProductImportResponse,
  ProductListFilter,
  ProductListItem,
  ProductsFilterData,
  UpdateStocksPricesResponse,
} from './apiTypes';
import api from './base';
import {
  mockFullImport,
  mockGetProductsFilterData,
  mockGetProductsList,
  mockProductExport,
} from './mocks/productsMocks';
import { ApiGroup } from './model/ApiGroup';

const PRODUCTS_URL = 'management/products';
const PRODUCTS_FULL_IMPORT = 'management/products/import/full';
const KAUFLAND_OFFERS_FULL_IMPORT = 'management/products/import/full/offers';
const ALLEGRO_OFFERS_FULL_IMPORT = 'management/products/import/full/offers/allegro';
const ALLEGRO_OFFERS_IMPORT_MISSING_PARAMATERS = 'management/products/import/missing-parameters';
const ALLEGRO_OFFERS_EXPORT_NEW_PRODUCT_MISSING_PARAMETERS = 'management/products/export/new-product-parameters-report';
const ALLEGRO_OFFERS_EXPORT_EXISTING_PRODUCT_MISSING_PARAMETERS = 'management/products/export/existing-product-parameters-report';
const STOCKS_UPDATE_URL = 'management/products/availability/stock';
const PRICES_UPDATE_URL = 'management/products/availability/price';
const FILTER_DATA_URL = 'management/products/filter-data';
export const IMPORT_MEDIA_URL = 'management/products/import/media-urls';
export const EXPORT_MEDIA_URL = 'management/products/export/media-urls';
export const IMPORT_CATEGORIES_URL = 'management/products/import/category-parameters';
export const EXPORT_CATEGORIES_URL = 'management/products/export/category-parameters';
export const IMPORT_DELIVERY_DELAY_URL = 'management/products/import/delivery-delays';
export const EXPORT_DELIVERY_DELAY_URL = 'management/products/export/delivery-delays';
export const IMPORT_BRANDS_URL = 'management/products/import/brand-descriptions';
export const EXPORT_BRANDS_URL = 'management/products/export/brand-descriptions';
export const IMPORT_LOGISTICS_URL = 'management/products/import/logistics';
export const EXPORT_LOGISTICS_URL = 'management/products/export/logistics';

export async function importProducts(
  url: string,
  file: FormData,
  partnerCode: string | null = null,
) {
  return api.post<string | BackendExceptionResponse>(url, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
    params: { partnerCode },
  });
}

export async function fullImportProducts(file: FormData) {
  return api.post<ProductImportResponse>(PRODUCTS_FULL_IMPORT, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function fullImportOffers(file: FormData, type: 'KAUFLAND' | 'ALLEGRO') {
  const urls = {
    KAUFLAND: KAUFLAND_OFFERS_FULL_IMPORT,
    ALLEGRO: ALLEGRO_OFFERS_FULL_IMPORT,
  };
  return api.post<ProductImportResponse>(urls[type], file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export async function importOffersMissingParameters(file: FormData) {
  return api.post<ProductImportResponse>(ALLEGRO_OFFERS_IMPORT_MISSING_PARAMATERS, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

api.mock.onPost(ALLEGRO_OFFERS_IMPORT_MISSING_PARAMATERS).reply(
  202,
  'Process is asynchronous. Wait and check status of offers in uploaded file.',
);

export type FilterParams = {
  categories: string;
  brands: string;
  marketplaces: string[];
  minStock: string;
};

export async function createReportNewProductMissingParameters(params: FilterParams) {
  return api.post<Blob>(`${ALLEGRO_OFFERS_EXPORT_NEW_PRODUCT_MISSING_PARAMETERS}`, params, {
    responseType: 'blob',
  });
}

export async function createReportExistingProductMissingParameters(params: FilterParams) {
  return api.post<Blob>(`${ALLEGRO_OFFERS_EXPORT_EXISTING_PRODUCT_MISSING_PARAMETERS}`, params, {
    responseType: 'blob',
  });
}

const fullImportError = {
  httpStatus: 400,
  code: 'VALIDATION_ERROR',
  codeDescription: 'Validation of request failed',
  details: 'Input file contains duplicate product_id',
};

let useErrorResponseOnce = true;

api.mock.onPost(PRODUCTS_FULL_IMPORT).reply(() => {
  if (useErrorResponseOnce) {
    useErrorResponseOnce = false;
    return [400, JSON.stringify(fullImportError)];
  }

  return [200, JSON.stringify(mockFullImport)];
});
api.mock.onPost(KAUFLAND_OFFERS_FULL_IMPORT).reply(200, mockFullImport);
api.mock.onPost(ALLEGRO_OFFERS_FULL_IMPORT).reply(200, mockFullImport);

export async function deleteProducts(file: FormData) {
  return api.delete<string | BackendExceptionResponse>(PRODUCTS_URL, {
    headers: { 'Content-Type': 'multipart/form-data' },
    data: file,
  });
}

api.mock.onDelete(PRODUCTS_URL).reply(200, '');

export async function updateStocks(file: FormData) {
  return api.put<UpdateStocksPricesResponse>(STOCKS_UPDATE_URL, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

api.mock.onPut(STOCKS_UPDATE_URL).reply(200, { hasErrors: false });

export async function updatePrices(file: FormData) {
  return api.put<UpdateStocksPricesResponse>(PRICES_UPDATE_URL, file, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

api.mock.onPut(PRICES_UPDATE_URL).reply(200, { hasErrors: false });

export async function exportProducts(url: string, filters: any) {
  return api.post<string | BackendExceptionResponse>(url, filters);
}

api.mock.onPost(EXPORT_BRANDS_URL).reply(200, '');
api.mock.onPost(EXPORT_CATEGORIES_URL).reply(200, '');
api.mock.onPost(EXPORT_MEDIA_URL).reply(200, '');
api.mock.onPost(EXPORT_LOGISTICS_URL).reply(200, '');
api.mock.onPost(EXPORT_DELIVERY_DELAY_URL).reply(200, '');

export async function getProductList(
  apiGroup:string,
  request: PagedRequest<ProductListFilter>,
) {
  return api.post<PagedResponse<ProductListItem>>(`${PRODUCTS_URL}/api-groups/${apiGroup}`, request);
}

api.mock.onPost(/\/management\/products\/api-groups\/.+/).reply(200, mockGetProductsList);

export async function exportProductList(
  apiGroup: ApiGroup,
  request: PagedRequest<ProductListFilter>,
) {
  return api.post<string | BackendExceptionResponse>(`management/products/export/api-groups/${apiGroup}`, request);
}

api.mock.onPost(/\/management\/products\/export\/api-groups\/.+/).reply(200, mockProductExport);

export async function getProductsFilterData(
  apiGroup: ApiGroup,
  signal?: AbortSignal,
): Promise<ProductsFilterData> {
  return api.get<ProductsFilterData>(
    `${FILTER_DATA_URL}/${apiGroup}`,
    { signal },
  )
    .then(response => response.data);
}

api.mock.onGet(/\/management\/products\/filter-data\/.+/).reply(200, mockGetProductsFilterData);
