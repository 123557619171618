import React, { useEffect } from 'react';
import {
  Panel, FlexboxGrid, Stack, Nav, IconButton, Grid, Row, Col, InputNumber, SelectPicker,
} from 'rsuite';
import {
  MdClose,
} from 'react-icons/md';

import PackageItems from '../PackageItems/PackageItems';

import { useStoreActions, useStoreState } from '../../../store/hooks';
import { LabelPackage, PackageProportions, VolumeMode } from '../../../store/labels/labelStoreTypes';

function LabelPackageForm({ labelPackage } : { labelPackage: LabelPackage }) {
  const packageSizes = useStoreState(state => state.catalogs.packageSizes);

  const {
    removePackage,
    changeVolumeMode,
    changeProportions,
  } = useStoreActions(actions => actions.labels);

  const fetchPackageSizes = useStoreActions(actions => actions.catalogs.fetchPackageSizes);

  const handlePackageDeletion = () => {
    removePackage(labelPackage.packageId);
  };

  const handleChangeVolumeMode = (mode:VolumeMode) => {
    changeVolumeMode({ labelPackage, mode });
  };

  const changeSize = (proportions: PackageProportions) => {
    changeProportions({ labelPackage, proportions, size: null });
  };

  const changePackageSize = (size: string | null) => {
    if (size === null) return;
    changeProportions({ labelPackage, proportions: null, size });
  };

  useEffect(() => {
    fetchPackageSizes();
  }, [fetchPackageSizes]);

  const cm3 = (
    <>
      cm
      <sup>3</sup>
    </>
  );

  return (
    <Panel bordered>
      <Grid fluid>
        <Row>
          <Col xs={6}>
            <h5>
              Package
              {' '}
              {labelPackage.packageId}
            </h5>
          </Col>
          <Col xs={16}>
            <Nav appearance="subtle" activeKey={labelPackage.volumeMode} onSelect={handleChangeVolumeMode}>
              <Nav.Item eventKey="simple">Size</Nav.Item>
              <Nav.Item eventKey="dimensions">Dimensions</Nav.Item>
              <Nav.Item eventKey="volume">Volume</Nav.Item>
            </Nav>
          </Col>
          <Col xs={2}>
            <IconButton
              icon={<MdClose />}
              appearance="subtle"
              onClick={handlePackageDeletion}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={24}>
            <FlexboxGrid>
              {labelPackage.volumeMode !== 'simple' && (
              <FlexboxGrid.Item colspan={6}>
                <Stack spacing={2}>
                  <span>Weight</span>
                  <InputNumber
                    value={labelPackage.proportions.weight}
                    className="input-number-no-arrow"
                    scrollable={false}
                    onChange={(v) => {
                      if (v === null) return;
                      changeSize({ weight: +v });
                    }}
                    postfix="g"
                  />
                </Stack>
              </FlexboxGrid.Item>
              )}
              <FlexboxGrid.Item colspan={18}>
                {labelPackage.volumeMode === 'simple' && (
                <Stack direction="row" spacing={3}>
                  <SelectPicker
                    data={packageSizes.map(s => ({
                      value: s.size,
                      label: `${s.size} (${s.weight / 1000} kg ${s.width}x${s.length}x${s.height} cm)`,
                    }))}
                    searchable={false}
                    cleanable={false}
                    onChange={changePackageSize}
                    value={labelPackage.size ?? ''}
                    style={{ width: 224 }}
                    placeholder="Select package size"
                  />
                </Stack>
                )}
                {labelPackage.volumeMode === 'dimensions' && (
                <Stack direction="row" spacing={3}>
                  <span>Width</span>
                  <InputNumber
                    className="input-number-no-arrow"
                    scrollable={false}
                    value={labelPackage.proportions.dimension1}
                    onChange={(v) => {
                      if (v === null) return;
                      changeSize({ dimension1: +v });
                    }}
                    postfix="cm"
                  />
                  <span>Length</span>
                  <InputNumber
                    className="input-number-no-arrow"
                    scrollable={false}
                    value={labelPackage.proportions.dimension2}
                    onChange={(v) => {
                      if (v === null) return;
                      changeSize({ dimension2: +v });
                    }}
                    postfix="cm"
                  />
                  <span>Height</span>
                  <InputNumber
                    className="input-number-no-arrow"
                    scrollable={false}
                    value={labelPackage.proportions.dimension3}
                    onChange={(v) => {
                      if (v === null) return;
                      changeSize({ dimension3: +v });
                    }}
                    postfix="cm"
                  />
                </Stack>
                )}
                {labelPackage.volumeMode === 'volume' && (
                <Stack direction="row" spacing={3}>
                  <span>Volume</span>
                  <InputNumber
                    className="input-number-no-arrow"
                    scrollable={false}
                    value={labelPackage.proportions.volume}
                    onChange={(v) => {
                      if (v === null) return;
                      changeSize({ volume: +v });
                    }}
                    postfix={cm3}
                  />
                </Stack>
                )}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </Col>
        </Row>
        <Row className="mt-20">
          <Col xs={24}>
            <PackageItems labelPackage={labelPackage} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}

export default LabelPackageForm;
